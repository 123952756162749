import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoenixAccountInfoDialogService } from '@phoenix/components/phoenix-dialogs/phoenix-account-info-dialog.service';
import { AssetDto, UserDto } from '@phoenix/gapicon/dto.module';
import { UserConfigDto } from '@phoenix/gapicon/user/dto/user-config-dto';
import { UserApiService } from '@phoenix/gapicon/user/services/user-api.service';
import { PhoenixCommunicationSubject } from '@phoenix/services/phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '@phoenix/services/phoenix-communication-service/phoenix-communication.service';
import { PhoenixUserWizardService } from '@phoenix/services/phoenix-wizards/phoenix-user-wizard.service';
import * as _ from 'lodash-es';
import { Subscription } from 'rxjs';

import { PhoenixUserConfigType } from '../../../../@phoenix/enums/phoenix-user-config-type.enum';
import { UserGroupDto } from '../../../../@phoenix/gapicon/group/dto/user-group-dto';
import { UserGroupType } from '../../../../@phoenix/gapicon/group/enums/usergroup-status.enum';
import { PhoenixAssetBranchselectorDialogService } from '../../../../@phoenix/templates/phoenix-dialog/phoenix-asset-branchselector-dialog/phoenix-asset-branchselector-dialog.service';
import { PhoenixTenantSelectorDialogService } from '../../../../@phoenix/templates/phoenix-dialog/phoenix-tenantselector-dialog/phoenix-tenant-selector-dialog.service';
import { PermissionService } from '../../../guards/permission.service';
import { AddUserToGroupDialogResult } from '../../../main/groupmanagement/groupmanagement-tenant/groupmanagement-tenant-content/classes/add-edit-user-to-group-dialog-result';

import { ToolbarIconMenuItem } from './toolbar-menu-item/toolbar-icon-menu-item';
import { ToolbarImageMenuItem } from './toolbar-menu-item/toolbar-image-menu-item';
import { ToolbarMenuItem } from './toolbar-menu-item/toolbar-menu-item';
import { ToolbarMenu } from './toolbar-menu/toolbar-menu';
import { waitForAsync } from '@angular/core/testing';

/**
 * FuseToolbarComponent
 */
@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnDestroy {
  public canChangeTenant: boolean = false;
  public canChangeAssetBranch: boolean = false;
  public currentTenant: string;
  public currentAssetBranch: string;
  public languageMenu: ToolbarMenu;
  public languageMenuEntries: ToolbarMenuItem[];
  public userMenu: ToolbarMenu;
  public userMenuEntries: ToolbarMenuItem[];
  private context: any;
  private flagPath: string = 'assets/images/flags/';
  private imagePath: string = 'assets/images/';
  private tenantChangedSubscription: Subscription;
  private userChangedSubscription: Subscription;
  private userGroupChangedSubscription: Subscription;
  private assetBranchChangedSubscription: Subscription;

  private permissionChangedSubscription: Subscription;
  private userBlockedChangedSubscription: Subscription;
  private hasPermission: boolean = true;

  public constructor(private router: Router,
    private translate: TranslateService,
    private permissionService: PermissionService,
    private sidebarService: FuseSidebarService,
    private phoenixUserWizardService: PhoenixUserWizardService,
    private communicationService: PhoenixCommunicationService,
    private accountInfoDialogs: PhoenixAccountInfoDialogService,
    private userService: UserApiService,
    private tenantSelectorDialogService: PhoenixTenantSelectorDialogService,
    private assetBranchSelectorDialogService: PhoenixAssetBranchselectorDialogService,
  ) {
    this.loadUserMenu();
    this.updateUserMenuEntries();


    this.userChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.UserChanged)
      .subscribe(async () => {
        this.loadUserMenu();
        const tenants = localStorage.getItem("availableTenants") != null ?
          JSON.parse(localStorage.getItem("availableTenants")) : await this.permissionService.getAvailableTenants().toPromise();
        if (tenants.length > 0) {
          var selectedTenants: string[] = localStorage.getItem("selectedTenants") ? JSON.parse(localStorage.getItem("selectedTenants")) : [];
          if (selectedTenants.length > 0 && tenants.length > 0) {
            var tenant = tenants.find((t: AssetDto) => t.id === selectedTenants[0])
            if (tenant) {
              this.currentTenant = tenant.name;
            }
          }
          if (tenants.length > 0) {
            this.canChangeTenant = tenants.length != 1 ? true : false;

          }
        }
      });

    this.tenantChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.TenantChanged)
      .subscribe((tenants: AssetDto[]) => {
        if (tenants.length == 0) {
          tenants = localStorage.getItem("availableTenants") != null ?
            JSON.parse(localStorage.getItem("availableTenants")) : []
        }
        if (tenants.length > 0) {

        }

      });

    this.assetBranchChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.AssetBranchChanged)
      .subscribe((assetBranches: AssetDto[]) => {
        if (assetBranches.length > 0) {
          this.currentAssetBranch = assetBranches[0].name;
          this.canChangeAssetBranch = _.get(this.permissionService, 'availableAssetRootBranches', []).length > 1;
        }
      });

    this.userGroupChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.UserGroupChanged)
      .subscribe(() => {
        this.canChangeAssetBranch = _.get(this.permissionService, 'availableAssetRootBranches', []).length > 1;
      });


    this.permissionChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.UserUnauthorized)
      .subscribe(() => {
        this.hasPermission = false;
        this.updateUserMenuEntries();
      });

    this.userBlockedChangedSubscription = this.communicationService.getObservable(PhoenixCommunicationSubject.UserIsBlocked)
      .subscribe(() => {
        this.hasPermission = false;
        this.updateUserMenuEntries();
      });

    /*
  const profileSettingsEntry: ToolbarIconMenuItem = new ToolbarIconMenuItem('prSe', 'TOOLBAR.PROFILESETTINGS', (): void => {
    this.openProfileSettings();
  }, 'account_circle', this);

  const changePasswordEntry: ToolbarIconMenuItem = new ToolbarIconMenuItem('prCp', 'TOOLBAR.CHANGEPASSWORD', (): void => {
    this.openChangePassword();
  }, 'vpn_key', this);

  const logoutEntry: ToolbarIconMenuItem = new ToolbarIconMenuItem('logout', 'Logout', this.logout, 'exit_to_app', this);

  console.log("####this.hasPermission:", this.hasPermission);
  this.userMenuEntries = this.hasPermission ? [profileSettingsEntry, changePasswordEntry, logoutEntry] : [logoutEntry];
  */

    const german: ToolbarImageMenuItem = new ToolbarImageMenuItem('de', 'LANGUAGES.GERMAN', this.setLanguage, `${this.flagPath}de.png`, this);
    const english: ToolbarImageMenuItem = new ToolbarImageMenuItem('en', 'LANGUAGES.ENGLISH', this.setLanguage, `${this.flagPath}en.png`, this);
    const french: ToolbarImageMenuItem = new ToolbarImageMenuItem('fr', 'LANGUAGES.FRENCH', this.setLanguage, `${this.flagPath}fr.png`, this);
    const italian: ToolbarImageMenuItem = new ToolbarImageMenuItem('it', 'LANGUAGES.ITALIAN', this.setLanguage, `${this.flagPath}it.png`, this);

    this.languageMenuEntries = [
      german,
      english,
      french,
      italian,
    ];
    // TODO: Das hier sollte noch schöner werden.
    this.languageMenu = new ToolbarMenu('languageMenu', this.translate.currentLang, 's-16', 'arrow_drop_down',
      'flag mr-8', `${this.flagPath}${this.translate.currentLang}.png`, 'iso text-uppercase');
    this.currentTenant = _.get(this.permissionService, 'tenants', []).length > 0 ? this.permissionService.tenants[0].name : undefined;
    this.currentAssetBranch = _.get(this.permissionService, 'assetBranches', []).length > 1 ? this.permissionService.assetBranches[0].name : undefined;
    this.canChangeTenant = _.get(this.permissionService, 'availableTenants', []).length > 1;
    this.canChangeAssetBranch = _.get(this.permissionService, 'availableAssetRootBranches', []).length > 1;
  }

  private updateUserMenuEntries(): void {
    const profileSettingsEntry: ToolbarIconMenuItem = new ToolbarIconMenuItem('prSe', 'TOOLBAR.PROFILESETTINGS', (): void => {
      this.openProfileSettings();
    }, 'account_circle', this);

    const changePasswordEntry: ToolbarIconMenuItem = new ToolbarIconMenuItem('prCp', 'TOOLBAR.CHANGEPASSWORD', (): void => {
      this.openChangePassword();
    }, 'vpn_key', this);

    const logoutEntry: ToolbarIconMenuItem = new ToolbarIconMenuItem('logout', 'Logout', this.logout, 'exit_to_app', this);

    // Set userMenuEntries based on hasPermission
    this.userMenuEntries = this.hasPermission ? [profileSettingsEntry, changePasswordEntry, logoutEntry] : [logoutEntry];
  }

  public async changeTenant(): Promise<void> {
    const newTenant: AssetDto[] = await this.tenantSelectorDialogService.showTenantSelectorDialog(this.permissionService.availableTenants, true).toPromise();
    if (Array.isArray(newTenant) && newTenant.length) {
      this.permissionService.tenants = newTenant;
      await this.permissionService.refreshBranchesForSelectedTenant();
      this.communicationService.emit(PhoenixCommunicationSubject.TenantChanged, this.permissionService.tenants);
      this.router.navigate(['/']);
    }
  }


  public async changeAssetBranch(): Promise<void> {
    const newAssetBranch: AssetDto[] =
      await this.assetBranchSelectorDialogService.showAssetBranchSelectorDialog(this.permissionService.availableAssetRootBranches, true).toPromise();
    if (Array.isArray(newAssetBranch) && newAssetBranch.length) {
      this.permissionService.assetBranches = newAssetBranch;
      this.permissionService.updateGroupAssetPairs();
      this.communicationService.emit(PhoenixCommunicationSubject.AssetBranchChanged, this.permissionService.assetBranches);
      /*
      this.communicationService.emit(PhoenixCommunicationSubject.ReloadCheckpointPage);
      this.communicationService.emit(PhoenixCommunicationSubject.UpdateDateTimePickersSelectedPreset);
      */
      this.router.navigate(['/']);
    }
  }

  /**
   * gets currently logged in user from gapicon, else returns 'unknown'
   * @returns {string}
   */
  public getCurrentUser(): string {
    const userDto: UserDto = this.permissionService.user;
    return userDto ? `${userDto.firstName} ${userDto.lastName}` : '';
  }

  /**
   * deletes token and navigates to Login-Page
   */
  public logout(): void {
    this.context.permissionService.logout();
  }

  public ngOnDestroy(): void {
    this.userChangedSubscription.unsubscribe();
    this.tenantChangedSubscription.unsubscribe();
    this.userGroupChangedSubscription.unsubscribe();
    this.permissionChangedSubscription.unsubscribe();
    this.userBlockedChangedSubscription.unsubscribe();
  }

  /**
   * Shows change password dialog for current user
   */
  public openChangePassword(): void {
    //this.accountInfoDialogs.openChangePasswordDialog();
    // @ts-ignore
    window.open(window.__RUNTIME_CONFIG__.KC_URL + '/account/', '_blank');
  }

  /**
   * will open Profile Settings with option to change PW
   */
  public openProfileSettings(): void {
    this.phoenixUserWizardService.openEditUserWizard(this.permissionService.user);
  }

  /**
   * sets language to be used for translation
   * @param lang
   */
  public setLanguage(lang: ToolbarMenuItem): void {
    // Set the selected language for toolbar
    this.context.languageMenu.title = lang.id;
    localStorage.setItem('selectedLanguage', lang.id);
    this.context.languageMenu.imageSrc = lang.picture;
    // Use the selected language for translations
    this.context.translate.use(lang.id);

    // save the language settings:
    this.context.setUserLanguage(this.context.permissionService.user, lang.id);
    this.context.userService.updateUser(this.context.permissionService.user).toPromise();
  }

  public toggleSidebarOpened(key: string): void {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  private loadUserMenu(): void {
    this.userMenu = new ToolbarMenu('userMenu', this.getCurrentUser(), 's-16', 'arrow_drop_down', 'avatar', `${this.imagePath}avatars/profile.jpg`, 'username mr-12');
    this.updateUserMenuEntries();
  }

  private loadLanguageMenu(currentUser: UserDto): void {
    const locale: UserConfigDto = currentUser.configurations.find((value: UserConfigDto) => value.type === 'LOCALE');
    this.languageMenu = new ToolbarMenu('languageMenu', locale.value, 's-16', 'arrow_drop_down',
      'flag mr-8', `${this.flagPath}${locale.value}.png`, 'iso text-uppercase');
  }

  private setUserLanguage(userDto: UserDto, language: string): void {
    const config: UserConfigDto = userDto.configurations.find((conf: UserConfigDto) => {
      if (conf.type === PhoenixUserConfigType.Locale) {
        conf.value = language
      }
      return conf.type === PhoenixUserConfigType.Locale;
    });
    if (!config) {
      const conf: UserConfigDto = new UserConfigDto();
      conf.type = PhoenixUserConfigType.Locale;
      conf.value = language;
      userDto.configurations.push(conf);
    }
  }
}
